// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  padding-top: 55px;
  overflow-y: scroll;
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, "Microsoft YaHei", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scrollbar-width: none;
}

body::-webkit-scrollbar {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.main-content {
  padding: 4px;
}

.small-icon .icon {
  font-size: 1em !important;
}

.custom-footer {
  font-size: 1.1em;
}

@media only screen and (max-width: 600px) {
  .hide-on-mobile {
    display: none !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,iBAAiB;EACjB,kBAAkB;EAClB,oFAAoF;EACpF,mCAAmC;EACnC,kCAAkC;EAClC,qBAAqB;AACvB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,+EAA+E;AACjF;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE;IACE,wBAAwB;EAC1B;AACF","sourcesContent":["body {\n  margin: 0;\n  padding-top: 55px;\n  overflow-y: scroll;\n  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, \"Microsoft YaHei\", sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  scrollbar-width: none;\n}\n\nbody::-webkit-scrollbar {\n  display: none;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;\n}\n\n.main-content {\n  padding: 4px;\n}\n\n.small-icon .icon {\n  font-size: 1em !important;\n}\n\n.custom-footer {\n  font-size: 1.1em;\n}\n\n@media only screen and (max-width: 600px) {\n  .hide-on-mobile {\n    display: none !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
